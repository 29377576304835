//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Modal from '../modals/Modal'

export default {
    components: {
        Modal
    },
    data() {
        return {
            open: false,
            city_id: 3,
            weather: {
                today: {},
                other: []
            },
            modal_type: 'weather_cities',
            city: null,
            is_weather: false
        }
    },
    mounted() {
        this.getWeather();
    },
    methods: {
        getWeather(city_id = false) {
            if(localStorage.getItem('city_id') && typeof localStorage.getItem('city_id') != undefined) {
                this.city_id = localStorage.getItem('city_id');
            }

            if(city_id) {
                localStorage.setItem('city_id', city_id);
            }

            this.weather =  {
                today: {},
                other: []
            },
            this.$axios.get(`/api/v1/app/weather`, {
                params: {
                    city_id: city_id ? city_id : this.city_id,
                }
            }).then(({ data }) => {
                this.city = data[0].city.name;

                const today = new Date();

                data.forEach(el => {
                    let date = new Date(el.day);

                    let dataItem = {
                        weekDay: el.day,
                        date: el.day,
                        icon: el.name,
                        class: el.name,
                        temp: Math.ceil(Number(el.temp))
                    };
                    if (date.getDate() == today.getDate() && date.getMonth() == today.getMonth() && date.getFullYear() == today.getFullYear()) {
                        dataItem.weekDay = this.getWeekDay(el.day, true);
                        this.weather.today = dataItem;
                    } else {
                        dataItem.weekDay = this.getWeekDay(el.day, false);
                        this.weather.other.push(dataItem)
                    }
                });
                if(this.weather.today && this.weather.other.length) {
                    this.is_weather = true;
                }
            })
        },
        getWeekDay: function(date, full) {
            if(full) {
                date = new Date(date).toLocaleDateString(
                    'ru',{
                        weekday: 'long',
                    });
            } else {
                date = new Date(date).toLocaleDateString(
                    'ru',{
                        weekday: 'short',
                    });
            }
            return date;
        },
    }
}
