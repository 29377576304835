//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  props: ['article'],
  data() {
    return {
      image: '/images/placeholders/placeholder.jpg',
      storage: '/storage/news/',
    }
  },
  mounted() {
    if(this.article.image) {
      this.image = this.$axios.defaults.baseURL + this.storage + this.article.image;
    } 
  }
}
