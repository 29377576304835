//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['content'],
    mounted() {
        if(this.content.image) {
            this.image = this.storage + this.content.image;
        }
    },
    methods: {
        initVideoPlayer(video_code, event) {
            event.target.parentElement.innerHTML =`
            <iframe
                src="https://www.youtube.com/embed/${video_code}?autoplay=1&mute=1" frameborder="0" allowfullscreen>
            </iframe>
            `
        }
    }
}
