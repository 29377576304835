//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    data() {
        return {
            currency: [],
            date: new Date()
        }
    },
    mounted() {
        this.getCurrency();
        // const current = new Date();
        // this.date = service.getNumberDate(current);

        // this.date = this.$store.dispatch('getNumberDate', {
        //     date: date
        // });
    },
    methods: {
        getCurrency() {
            this.$axios.get(`/api/v1/app/currency`).then(({ data }) => {
                data.map(function(item) {
                    item.purchase = Number(item.purchase).toFixed(2);
                    item.sale = Number(item.sale).toFixed(2);
                    return item;
                });
                this.currency.push(...data);
            })
        }
    }
}
