//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'modal',
    emits: ['close', 'id'],
    props: ['controller'],
    data() {
        return {
            text: null,
            list: null
        }
    },
    mounted() {
        if(this.$props.controller == 'horoscope_signs') {
            this.text = 'Выберите свой знак зодиака';
        } else {
            this.text = 'Выберите свой город'
        }
        this.getList();
    },
    methods: {
        hideModal() {
            let modal = document.querySelectorAll('.modal')[0];
            modal.classList.remove('opened');
            this.$emit('close', true);
        },
        getData(id) {
            this.$emit('id', id);
            this.hideModal();
        },
        getList() {
            this.$axios.get(`/api/v1/app/${this.$props.controller}`).then(({ data }) => {
                this.list = data;
            })
        }
    }
}
