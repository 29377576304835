//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Quote from '../insets/Quote'
import Weather from '../insets/Weather'
import Joke from '../insets/Joke'
import Horoscope from '../insets/Horoscope'
import Video from '../insets/Video'
import Currency from '../insets/Currency'
import VideoSlider from '../sliders/VideoSlider'
import CategorySlider from '../sliders/CategorySlider'

export default {
  components: {
    Quote,
    Weather,
    Joke,
    Horoscope,
    Video,
    Currency,
    VideoSlider,
    CategorySlider
  },
  props: ['page'],
  data() {
    return {
      inset: [],
    }
  },
  mounted() {
    this.getInset();
  },
  methods: {
    getInset() {
      let page = this.$props.page,
          total = Number(localStorage.getItem('insets')),
          multiplier = Math.floor(page / total);

      if(page > total) {
        if(total * multiplier != page) {
          page = page - (total * multiplier);
        } else {
          page = total;
        }
      }
    
      this.$axios.get(`/api/v1/app/insets`, {
              params: {
                  status: 'visible',
                  page: page,
                  sort: 'position|asc'
              }
      }).then(({ data }) => {
          localStorage.setItem('insets', data.total);

          if(data.data[0]) {
            data = data.data[0];

            let inset = {
                type: data.type == 'random' ? 'custom' : data.type,
                position: data.position,
                id: data.id,
                left: {
                  type: ''
                },
                right: {
                  type: ''
                }
            };
            if(data.type == 'custom' ) {
              data.left ? inset.left = data.left : inset.left.type = data.data.left_id;
              data.right ? inset.right = data.right : inset.right.type = data.data.right_id;
            } else if(data.type == 'categories'){
              inset['name'] = data.category ? data.category.name : null;
              inset['uri'] = data.category ? data.category.uri : null;
            } else if(data.type == 'random') {
              data.left.id ? inset.left = data.left : inset.left.type = data.left;
              data.right.id ? inset.right = data.right : inset.right.type = data.right;
            }
            this.inset = inset;
          }
      })
    },
  }
}
