//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: ['content'],
    data() {
        return {
            link: null,
            text: 'Донбас 24 - только важные новости',
            telegram_link: null
        }
    },
    mounted() {
        this.link = window.location.origin + '/storage/social/quote-' + this.$props.content.id + '.jpg';
        this.telegram_link = `https://t.me/share/url?url=${this.link}&text=${this.text}`;
    },
    methods: {
        shareFbImage() {
            this.shareStats('facebook');
            FB.ui(
                {
                    method: 'share',
                    href: this.link,
                    hashtag: '#цитата',
                    quote: this.text
                },
                function (response) {
                    if (response && response.post_id) {
                        this.shareStats('facebook')
                    }
                }
            )
        },
        
        shareStats(social) {
            return this.$axios.$post(`/api/v1/app/inset_stat`, {
                type: 'quote',
                social: social,
                inset_id: this.$props.content.id
            })
        }
    }
}
