//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlockCategory from '../blocks/BlockCategory';

export default {
  data() {
    return {
      news: [],
    }
  },
  props: ['category'],
  components: {
    BlockCategory
  },
  mounted() {
    this.$store.dispatch('news/getListForSlider', {
      page: 1,
      category: this.$props.category.uri,
      status: 'visible',
      sort: 'published_at|desc',
    }).then((data) => {
        this.news = data.news.data;
    })
  }
}
