//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BlockVideo from '../blocks/BlockVideo';

export default {
  data() {
    return {
      news: []
    }
  },
  components: {
    BlockVideo
  },
  mounted() {
    this.$store.dispatch('news/getListForSlider', {
      page: 1,
      video: true,
      status: 'visible',
      sort: 'published_at|desc',
    }).then((data) => {
        this.news = data.news.data;
    })
  }
}
